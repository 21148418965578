import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from './store/login-slice';
import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Topics = lazy(() => import('./topics/ManageTopic'));
const Questions = lazy(() => import('./questions/ManageQuestions'));

const Login = lazy(() => import('./user-pages/Login'));
const Logout = lazy(() => import('./user-pages/Logout'));

const UserList = lazy(() => import('./general-pages/UserListing'));


const AppRoutes = () => {
  const isLogin = useSelector((state) => state.login.isLogin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Async function to validate token (if you fetch from server or have any async ops)
    const validateToken = async () => {
      // Simulating an API call
      return new Promise((resolve) => {
        setTimeout(() => {
          const hasValidToken = Boolean(localStorage.getItem('access_token'));
          resolve(hasValidToken);
        }, 1000); // Fake delay
      });
    };

    const checkLoginStatus = async () => {
      const hasValidToken = await validateToken();
      if (hasValidToken) {
        dispatch(loginActions.setLogin());
      } else {
        dispatch(loginActions.setLogout());
      }
      setIsLoading(false);
    };

    checkLoginStatus();
  }, [dispatch]);


  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>


        <Route exact path="/">
          {isLogin ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Route>

        <Route path="/login">
          {isLogin ? <Redirect to="/dashboard" /> : <Login />}
        </Route>


        <Route path="/dashboard">
          {isLogin ? <Dashboard /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/logout" >
          <Logout />
        </Route>

        <Route exact path="/topics" >
          {isLogin ? <Topics /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/clients" >
          {isLogin ? <UserList /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/questions" >
          {isLogin ? <Questions /> : <Redirect to="/login" />}
        </Route>



      </Switch>
    </Suspense>
  );

}

export default AppRoutes;