import axios from 'axios';
import { API_URL } from '../../constants';


const API = axios.create({
    baseURL: API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    mode: 'no-cors'
});



export default API;